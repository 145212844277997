<template>
    <div class="content profile-content">

        <ProfileNavbar :title="$t('profile.parent.title')"></ProfileNavbar>
       <div class="mob-menu-title">
           {{ $t('profile.parent.title') }}
       </div>
        <div class="clearfix">
            <div class="bg-blue">
                <div class="col-md-12 parent-tabs">
                    <div class="tabsMenu">
                        <ul class="nav nav-tabs d-flex flex-row">
                            <li class="nav-item">
                                <a class="nav-link parent-tabs-title text-center" @click.prevent="setActive('requests')" :class="{ active: isActive('requests') }" href="#requests">{{ $t("profile.parent.requests-tab-title") }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link parent-tabs-title text-center" @click.prevent="setActive('approved')" :class="{ active: isActive('approved') }" href="#approved">{{ $t("profile.parent.approved-tab-title") }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="bg-blue">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade" :class="{ 'active show': isActive('requests') }" id="requests">
                      <div v-for="parent in parentRequest ">
                      <div v-if="parent.status==2"  class="request-card row">
                            <div class="col-lg-4 col-md-4 col-sm-12 text-lg-left text-md-left text-sm-center text-center pb-md-0 pb-2">
                                {{parent.child.first_name + ' ' + parent.child.last_name+' '}}{{parent.child.patrononymic}}
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 text-lg-left text-md-left text-sm-center text-center pb-md-0 pb-2">
                              {{ $t('profile.birth-date-label') }}: {{parent.child.birth_date.split('-').reverse().join('.')}}
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-12 text-lg-right text-md-right text-sm-center text-center">
                                <button @click="parentSubmit(parent.id)" class="btn btn-accept btn-blue">  {{ $t('profile.submit') }}</button>
                                <button @click="parentCancel(parent.id)" class="btn btn-reject">{{ $t('profile.decline') }}</button>
                            </div>
                        </div>
                      </div>
                    </div>
                  <b-modal id="parent-cancel" hide-footer hide-header centered size="lg">
                    <div class="content">
                      <div>{{ $t('profile.calendar.confirm-cancel') }}</div>
                      <div style="display:flex;justify-content: space-between">
                        <button  @click="$bvModal.hide('parent-cancel');" type="button" class="btn mx-auto mt-4 btn-blue">{{ $t('profile.notes.cancel-btn-2') }}</button>
                        <button  @click="parentCancel(parentId)" type="button" class="btn mx-auto mt-4 btn-blue">{{ $t('profile.calendar.confirm-cancellation-btn') }}</button>
                      </div>
                    </div>
                  </b-modal>

                  <div v-for="parent in parentRequest ">
                    <div v-if="parent.status==1" class="tab-pane fade" :class="{ 'active show': isActive('approved') }" id="approved">
                        <div class="approved-card row">
                            <div class="approved-card-photo col-lg-5 col-md-5 col-sm-12">
                                <img width="150" class="mr-2" :src="parent.child.avatar" alt="">
                                <div>{{parent.child.first_name + ' ' + parent.child.last_name+' '}}{{parent.child.patrononymic}}</div>
                            </div>
                            <div class="approved-card-info col-lg-7 col-md-7 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('profile.parent.birthday-label') }}</label>
                                    <el-input disabled
                                        :placeholder="parent.child.birth_date.split('-').reverse().join('.')"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('profile.representative-tel-label') }}</label>
                                    <el-input disabled
                                        :placeholder="'+'+parent.child.phone"></el-input>
                                </div>
                            </div>
                            <button  @click="openModal(parent.id)" type="button" class="btn mx-auto mt-4 btn-blue">{{ $t('profile.notes.cancel-btn-2') }}</button>

                        </div>
                    </div>
                 </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- </div> -->
</template>
<script>
    import Vue from 'vue';
    import ProfileNavbar from "@/components/ProfileNavbar";


    export default {
        name: 'Parent',
        components: {
            ProfileNavbar,
        },
        data() {
            return {
                parentId:0,
                parentRequest:'',
                newRequests: [],
                approvedRequests: [],
                activeItem: 'requests',
            }
        },

      mounted() {
        this.getParents()
      },
      methods: {
          openModal(id){
            this.parentId=id
            this.$bvModal.show('parent-cancel');
          },
          parentSubmit(id){
            this.$http.post(window.API_ROOT + '/api/parent/'+id+'?_method=PUT&status_id=1')
                .then((res) => {
                  Vue.toastr({
                    message: this.$t('profile.settings.request-accept')+'!',
                    description: this.$t('profile.settings.request-accept'),
                    type: 'success'
                  });
                  this.getParents()
                })
                .catch((e) => {

                });
          },
          parentCancel(id){
            this.$http.post(window.API_ROOT + '/api/parent/'+id+'?_method=PUT&status_id=3')
                .then((res) => {
                  Vue.toastr({
                    message: this.$t('profile.settings.success')+'!',
                    description: this.$t('profile.settings.request-canceled'),
                    type: 'success'
                  });
                  this.$bvModal.hide('parent-cancel');
                  this.getParents()
                })
                .catch((e) => {

                });
          },
          getParents(){
            this.$http.get(window.API_ROOT + '/api/parent')
                .then((res) => {
                  this.parentRequest=res.body.data
                  for(var i=0; i<this.parentRequest.length; i++){
                    this.parentRequest[i].child.avatar=window.API_ROOT + '/api/user/avatar?file=' + this.parentRequest[i].child.avatar
                  }
                })
                .catch((e) => {

                });
          },
            isActive (menuItem) {
                return this.activeItem === menuItem
            },
            setActive (menuItem) {
                this.activeItem = menuItem
            },
        },
    }
</script>
<style scoped>
.parent-tabs,
#myTabContent {
    max-width: 1120px;
}
</style>
